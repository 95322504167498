import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import CzestochowaPopup from "components/CzestochowaPopup"

import { Nav } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  Convenients,
} from "page_components/investment/stacja-kopernik"

const Investment = ({ location }) => {
  const title = "Copernicus Station"

  return (
    <Layout
      location={location}
      seo={{
        title: "Stacja Kopernik - Mieszkaj nowocześnie, w pierwszej klasie",
        description:
          "Zamieszkaj w sercu Częstochowy w gustownej inwestycji o charakterze stylowej kamienicy. Wyjątkowe apartamenty bezpośrednio od dewelopera już w sprzedaży.",
        image: withPrefix("/st-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Częstochowa", url: "/en/flats/czestochowa" }}
        subParent={{ label: "Flats", url: "/en/flats" }}
      />
      <Hero title={title} />
      <Nav
        title={title}
        investment="Copernicus Station"
        investmentRaw="stacja_kopernik"
      />
      <About title={title} />
      <Convenients />
      <Gallery />
      <Location />
      <ContactForm
        formType="sale"
        investment="Copernicus Station"
        investmentRaw="stacja_kopernik"
      />
      {/* <CzestochowaPopup /> */}
    </Layout>
  )
}

export default Investment
